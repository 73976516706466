.scrollbarr::-webkit-scrollbar {
    width: 4px;
  }
.cards:hover .carditem{
    display: block;

}
.bgImg{
    background: url(images/watermark.png);
    background-position: left;
    background-size: 450px;
}
.bgImg1{
    background: url(images/watermark.png);
    background-position: right;
    background-size: 229px;
    background-repeat: no-repeat;
}
.cards{
    box-shadow: 0px 0px 15px #c3c3c3;
    transition: background 2s linear;
}
.Bshadow{
    box-shadow: 0px 0px 15px #c3c3c3;
}
.containerC{
    background-color: #F3E7FF;
}
.btnSec{
    padding: 30px;
    box-shadow: 0 0px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
/* .carditem{
    background-color: blue;
    transition: background 2s linear;
} */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    background-color: #fff;
    border-bottom: 1px solid #899fff;
    margin-top: 12px;
    color: #000000;
    cursor: pointer;
    border-radius: 6px;
}
.button1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    background-color: #4338CA;    ;
    box-shadow: 0px 0px 7px #968cff;
    color: #ffffff;
    margin-top: 12px;
    cursor: pointer;
    border-radius: 6px;
}
.button2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 10px 12px;
    box-shadow: 0px 0px 7px #d9baff;
    color: #ffffff;
    margin-top: 12px;
    cursor: pointer;
    border-radius: 6px;
}
.imgStats img{
    display: none !important;
}
.statsA{
    display: block !important;
}
.redColor{
    color: #ffffff;
}
.arrowup{
    transform: rotate(90deg);
    color: #ffffff;
}
.arrowleft{
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: start;
    color: #ffffff;
}
.arrowdown{
    transform: rotate(180deg);
    
}
.hamburger{
    position: fixed;
    top: 4px; 
    right: 4px;
    display: none;
}
.bigBanner{
   padding: 30px;
   border-radius: 25px;
   background-color: #ffffff;
}
.btnBack{
    display: flex;
    justify-content:space-between;
    align-items: center;
    width: 100%;
}
@media screen and (max-width:799px) {
    .hamburger{
        display:block;
    }
    .catSec{
        display: none;
    }
    .catSecA{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 80%;
    }
    .bigBanner, .btnSec{
        border-radius: 8px;
    }
    .btnBack{
        display: none;
    }
}